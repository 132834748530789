import React, { useState } from "react";

// react carousel
import { Carousel } from "react-responsive-carousel";

// custom small screen checker helper
import { useIsSmall } from "../../../helpers/Hooks";

// images
import { main__logo } from "../../../helpers/Images";

// videos
import { HomeIntroSlider } from "../../../data/HomeData";

import CLoader from "../../base/loader/CLoader";

const HomeIntro = () => {
    // small screen checker
    const isSmall = useIsSmall();

    const [loading, setLoading] = useState(true);

    return (
        <>
            {loading && <CLoader />}

            {!isSmall ? (
                <>
                    {/* home slider */}
                    <div className="intro text-center" id="Home_Intro">
                        {/* FIXME: dots are not clickable have to fix that */}
                        <div className="home_slider_wrapper">
                            <Carousel
                                // animationHandler="fade"
                                showThumbs={false}
                                showStatus={false}
                                autoPlay={true}
                                infiniteLoop={true}
                                interval={4000}
                                showArrows={true}
                                showIndicators={false}
                                centerMode={true}
                                stopOnHover={false}
                            >
                                {HomeIntroSlider.map((d) => {
                                    return (
                                        <div className="home_slider" key={d.id}>
                                            <img alt={d.id} onLoad={() => setLoading(false)} src={d.img} />
                                        </div>
                                    );
                                })}
                            </Carousel>
                        </div>
                    </div>
                    {/* home slider end */}
                </>
            ) : (
                // mobile view
                <div className="mobile-intro">
                    <div className="intro text-center" id="Home_Intro">
                        <div className="headline-Wrapper-mobile">
                            <div className="text">
                                <div className="mobile-logo">
                                    <img onLoad={() => setLoading(false)} src={main__logo} alt="logo" />
                                </div>
                                <h1>
                                    LET US DESIGN
                                    <br />
                                    THE FUTURE
                                </h1>
                                <p>
                                    Globally the First Design brand aspiring to be the one-stop solution to all your worries. We aim to provide every
                                    single design service under one roof!
                                </p>
                            </div>
                        </div>
                        <div className="home_slider_wrapper_mobile">
                            <Carousel showThumbs={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={3000} showArrows={false}>
                                {HomeIntroSlider.map((d) => {
                                    return (
                                        <div className="home_slider" key={d.id}>
                                            <img alt={d.id} onLoad={() => setLoading(false)} src={d.img} />
                                        </div>
                                    );
                                })}
                            </Carousel>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default HomeIntro;
