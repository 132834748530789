import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Carousel } from "react-responsive-carousel";
import { useIsSmall } from "../../../helpers/Hooks";
import { Fade } from "../../animation";
import CLoader from "../loader/CLoader";

const ProjectView = ({ images, onClick, projectData }) => {
    const [loading, setLoading] = useState(true);

    // const isSmall = useIsSmall();
    return (
        <>
            <Fade uqkey="socialWorks__ProjectView">
                <div className="full-project-view-wrapper">
                    <div className="close-btn" onClick={onClick}>
                        <AiOutlineClose />
                    </div>
                    {loading && <CLoader />}
                    {/* <div className="container-fluid"> */}
                    <div className="row">
                        <div className="col-lg-8 p-0">
                            <Carousel showIndicators={false} showStatus={false} axis="horizontal" showArrows={true} infiniteLoop={true}>
                                {images.map((img, i) => (
                                    <div className="items" key={i}>
                                        <img src={img} alt="project images" onLoad={() => setLoading(false)} />
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                        <div className="col-lg-4 p-0">
                            <div className="project-info-wrapper">
                                {projectData.map((info, i) => (
                                    <div className="project-info" key={i}>
                                        <h5 className="text-uppercase text-subheading">PROJECT NAME -</h5>
                                        <h3 className="text-uppercase text-heading">{info.name}</h3>
                                        <div className="project-box">
                                            <h5 className="text-uppercase text-subheading">CLIENT -{info.client}</h5>
                                            <h5 className="text-uppercase text-subheading">PROJECT VALUE -{info.value}</h5>
                                            <h5 className="text-uppercase text-subheading">PROJECT AREA (sqft)-{info.area}</h5>
                                            <h5 className="text-uppercase text-subheading">CONSTRUCTION PERIOD -{info.year}</h5>
                                            <h5 className="text-uppercase text-subheading">DESIGN TYPOLOGY - {info.design}</h5>
                                            <h5 className="text-uppercase text-subheading">STATUS - {info.status}</h5>
                                            <h5 className="text-uppercase text-subheading">CATEGORY - {info.category}</h5>
                                        </div>
                                        <h5 className="text-uppercase text-subheading">PROJECT DESCRIPTION -</h5>
                                        <p className="text-paragraph">{info.des}</p>
                                        {/* {isSmall && <p className="text-paragraph">{`${info.des.substring(0, 150)}...`}</p>} */}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </div>
            </Fade>
        </>
    );
};

export default ProjectView;
