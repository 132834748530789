import React from "react";
import { Link } from "react-router-dom";

const Award = ({ title, place, year, info, org }) => {
    return (
        <div className="award">
            <div className="award-content">
                <div className="text-subheading">{year}</div>
                <div className="awards-info">
                    <h4 className="text-heading">{title}</h4>
                    <Link to="/projects">
                        <p className="text-paragraph pt-0 m-0">
                            {org} | {place}
                        </p>
                    </Link>
                    <p className="text-paragraph pt-0 m-0">{info}</p>
                </div>
            </div>
        </div>
    );
};

export default Award;
