import {
    home_slider_1,
    home_slider_2,
    home_slider_3,
    home_slider_4,
    home_slider_5,
    home_slider_6,
    home_slider_7,
    home_slider_8,
    home_slider_9,
    home_slider_13,
    home_slider_clients,
} from "../helpers/Images";

// -------------------------------------------
// home data
// -------------------------------------------

export const HomeIntroSlider = [
    { id: "intro_slider_1", img: home_slider_1 },
    { id: "intro_slider_2", img: home_slider_2 },
    { id: "intro_slider_6", img: home_slider_6 },
    { id: "intro_slider_13", img: home_slider_13 },
    { id: "intro_slider_7", img: home_slider_7 },
    { id: "intro_slider_3", img: home_slider_3 },
    { id: "intro_slider_clients", img: home_slider_clients },
    { id: "intro_slider_8", img: home_slider_8 },
    { id: "intro_slider_4", img: home_slider_4 },
    { id: "intro_slider_13", img: home_slider_13 },
    { id: "intro_slider_9", img: home_slider_9 },
    { id: "intro_slider_5", img: home_slider_5 },
];
