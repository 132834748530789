import { SiBandsintown } from "react-icons/si";
import { TbBuildingPavilon } from "react-icons/tb";
import { GiBrickWall } from "react-icons/gi";
import { MdViewModule } from "react-icons/md";
import { GrInstall } from "react-icons/gr";

export const ServicesData = [
    {
        id: "ser1",
        name: "ARCHITECTURE",
        icon: <TbBuildingPavilon />,
    },
    {
        id: "ser2",
        name: "INTERIOR",
        icon: <GiBrickWall />,
    },
    {
        id: "ser3",
        name: "TOWNSHIP",
        icon: <SiBandsintown />,
    },
    {
        id: "ser3",
        name: "MODULAR",
        icon: <MdViewModule />,
    },
    {
        id: "ser3",
        name: "INSTALLATION",
        icon: <GrInstall />,
    },
];
