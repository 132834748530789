import React, { useEffect, useState } from "react";
import { CLoader, FloatingSocial, Wrapper } from "../components";
import { useIsSmall } from "../helpers/Hooks";
import { Footer, Header } from "../layout";

const Terms = () => {
    const [Cloader, setCloader] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setCloader(false);
        }, 1000);
    }, []);
    // small screen checker
    const isSmall = useIsSmall();
    return (
        <Wrapper id="privicy" pageKey="vbridge__terms__page">
            {/* header */}
            <Header />
            {/* header end */}
            {Cloader ? (
                <CLoader />
            ) : (
                <>
                    {/* news content */}
                    <div className="content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-9">
                                    <div className="term">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="title">
                                                    <h3>TERMS OF SERVICES</h3>
                                                    <p>lorem ipsum</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="desc">
                                                    <p>
                                                        Et sit et nullam at purus, eget. Fringilla massa neque scelerisque volutpat id viverra
                                                        tincidunt. Quam elit velit quam lectus posuere egestas in. Molestie leo, sollicitudin turpis
                                                        curabitur non. Sed gravida elementum nam cursus neque, amet mauris, enim. Malesuada nibh urna
                                                        tempor.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="term">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="title">
                                                    <h3>DESCRIPTION OF SERVICES</h3>
                                                    <p>lorem ipsum</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="desc">
                                                    <p>
                                                        Et sit et nullam at purus, eget. Fringilla massa neque scelerisque volutpat id viverra
                                                        tincidunt. Quam elit velit quam lectus posuere egestas in. Molestie leo, sollicitudin turpis
                                                        curabitur non. Sed gravida elementum nam cursus neque, amet mauris, enim. Malesuada nibh urna
                                                        tempor.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="term">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="title">
                                                    <h3>ACCEPTANCE OF TERMS</h3>
                                                    <p>lorem ipsum</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="desc">
                                                    <p>
                                                        Et sit et nullam at purus, eget. Fringilla massa neque scelerisque volutpat id viverra
                                                        tincidunt. Quam elit velit quam lectus posuere egestas in. Molestie leo, sollicitudin turpis
                                                        curabitur non. Sed gravida elementum nam cursus neque, amet mauris, enim. Malesuada nibh urna
                                                        tempor.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="term">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="title">
                                                    <h3>POLICY BOOK LINK</h3>
                                                    <p>lorem ipsum</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="desc">
                                                    <p>
                                                        Et sit et nullam at purus, eget. Fringilla massa neque scelerisque volutpat id viverra
                                                        tincidunt. Quam elit velit quam lectus posuere egestas in. Molestie leo, sollicitudin turpis
                                                        curabitur non. Sed gravida elementum nam cursus neque, amet mauris, enim. Malesuada nibh urna
                                                        tempor.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="sidebar">
                                        <h3>Content</h3>
                                        <h5>Treams of Service</h5>
                                        <h5>Description of service</h5>
                                        <h5>Acceptance of terms</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* news content end */}

                    {/* footer */}
                    <Footer />
                    {/* footer end */}
                </>
            )}
            {/* floating social */}
            {!isSmall && <FloatingSocial />}
            {/* floating social end */}
        </Wrapper>
    );
};

export default Terms;
