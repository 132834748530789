import React from "react";
import { FloatingSocial, Wrapper } from "../components";
import Sustainabilities from "../components/Sustainabilities/Sustainabilities";
import { SustainData } from "../data/SustainData";
import { useIsSmall } from "../helpers/Hooks";
import { Footer, Header } from "../layout";

const Sustainability = () => {
    // small screen checker
    const isSmall = useIsSmall();
    return (
        <Wrapper id="sustainability" pageKey="vbridge__news__page">
            {/* header */}
            <Header />
            {/* header end */}

            <div className="container">
                <h2 className="text-display text-uppercase text-center py-5">Sustainability</h2>
                <div className="row">
                    {SustainData.map((d) => (
                        <div className="col-lg-12 item-padding" key={d.id}>
                            <Sustainabilities
                                sustainIcon={d.icon}
                                sustainPercentage={d.sustainPercentage}
                                sustainInfo={d.sustainInfo}
                                color={d.color}
                            />
                        </div>
                    ))}
                </div>
            </div>
            {/* floating social */}
            {!isSmall && <FloatingSocial />}
            {/* floating social end */}
            {/* footer */}
            <Footer />
            {/* footer end */}
        </Wrapper>
    );
};

export default Sustainability;
