import React, { useEffect, useState } from "react";

// -------------------------------------------
// all css/scss files
// -------------------------------------------
// bootstrap scss
import "bootstrap/dist/css/bootstrap.css";
// react tabs
import "react-tabs/style/react-tabs.css";
// custom scss
import "./assets/scss/style.scss";

// -------------------------------------------

// -------------------------------------------
// custom page routes
// -------------------------------------------
import { BrowserRouter as Router } from "react-router-dom";
import Routing from "./router/Routing";
import Loader from "./components/base/loader/Loader";
// -------------------------------------------

import { GetSectionIndex } from "./helpers/context/ContextApi";
import ScrollToTop from "./helpers/ScrollToTop";
// import TagManager from "react-gtm-module/dist/TagManager";

import { analytics } from "./helpers/GTM.ts";

const App = () => {
    const [sectionIndex, setSectionIndex] = useState(0);
    const [loading, setLoading] = useState(true);

    // tag manager
    // useEffect(() => {
    //     TagManager.initialize({ gtmId: "GTM-5RHD4NH" });
    // }, []);
    useEffect(() => {
        analytics(window, document, "script", "dataLayer", "GTM-5RHD4NH");
    }, []);

    // loader
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    });

    return (
        <div className="outer">
            {loading && <Loader />}
            <GetSectionIndex.Provider value={{ sectionIndex, setSectionIndex }}>
                <Router>
                    <ScrollToTop />
                    <Routing />
                </Router>
            </GetSectionIndex.Provider>
        </div>
    );
};

export default App;
