import React from "react";
import { AboutTeam, FloatingSocial, Wrapper } from "../components";
import { useIsSmall } from "../helpers/Hooks";
import { Footer, Header } from "../layout";

const Team = () => {
    // small screen checker
    const isSmall = useIsSmall();
    return (
        <Wrapper id="team" pageKey="vbridge__news__page">
            {/* header */}
            <Header />
            {/* header end */}
            <AboutTeam />
            {/* floating social */}
            {!isSmall && <FloatingSocial />}
            {/* floating social end */}
            {/* footer */}
            <Footer />
            {/* footer end */}
        </Wrapper>
    );
};

export default Team;
