import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import { Spin } from "../../animation";
// import { TestiData } from "../../../data/HomeData";
import FullTesti from "./full-testi/FullTesti";
import { AnimatePresence } from "framer-motion";
import SpinBack from "../../animation/spin/SpinBack";
import SpinText from "../../animation/spin/SpinText";
import { TestiData } from "../../../data/TestiData";
import CLoader from "../../base/loader/CLoader";

const Testimonials = () => {
    const [loading, setLoading] = useState(true);

    return (
        <>
            {loading && <CLoader />}
            <div className="testimonial">
                {/* testimonials */}
                <div className="testis">
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="row">
                                {TestiData.map((testi, i) => (
                                    <div className="col-lg-6 item-padding">
                                        <div className="review">
                                            <img onLoad={() => setLoading(false)} src={testi.author_thumbnail} alt="" />
                                            <div className="user">
                                                <h3 className="text-uppercase text-heading">{testi.author_name}</h3>
                                                <p className="role text-uppercase text-subheading">{testi.author_role}</p>
                                                <p className="role text-subheading">{testi.testi}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                {/* testimonials end*/}
            </div>
        </>
    );
};

export default Testimonials;
