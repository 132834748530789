import React, { useState } from "react";
import { CLoader, FloatingSocial, Wrapper } from "../components";
import { Footer, Header } from "../layout";
import { GalleryData } from "../data/GalleryData";
import { useIsSmall } from "../helpers/Hooks";

const Gallery = () => {
    const [loading, setLoading] = useState(true);
    // small screen checker
    const isSmall = useIsSmall();
    return (
        <Wrapper id="gallery" pageKey="vbridge__new__projects__page">
            {/* header */}
            <Header />
            {/* header end */}
            {loading && <CLoader />}

            <div className="container">
                <h1 className="text-display text-center pb-5 text-uppercase">gallery</h1>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="all-projects">
                            <div className="row">
                                {/* <!-- all photos --> */}
                                {GalleryData.map((d, i) => (
                                    <div className="col-lg-4 item-padding">
                                        <div className="project p-0 m-0" key={d.id}>
                                            <div className="pro-img">
                                                <img onLoad={() => setLoading(false)} src={d.img} alt="" />
                                            </div>
                                            <div className="info">
                                                <h5 className="text-heading">{d.name}</h5>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {/* <!-- all photos end --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* floating social */}
            {!isSmall && <FloatingSocial />}
            {/* floating social end */}
            {/* footer */}
            <Footer />
            {/* footer end */}
        </Wrapper>
    );
};

export default Gallery;
