import React from "react";
import { Route, Routes } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

// -------------------------------------------
// page views
// -------------------------------------------
import Home from "../views/Home";
// import About from "../views/About";
import Projects from "../views/Projects";
import News from "../views/News";
import Team from "../views/Team";
import SocialWorks from "../views/SocialWorks";
import Testimonial from "../views/Testimonial";
import Career from "../views/Career";
import Contact from "../views/Contact";

import Faq from "../views/Faq";
import Privacy from "../views/Privacy";
// import Quote from "../views/Quote";
import Terms from "../views/Terms";
import Journey from "../views/Journey";
import Awards from "../views/Awards";
import Clients from "../views/Clients";
import Gallery from "../views/Gallery";
import Services from "../views/Services";
import Blogs from "../views/Blogs";
import Sustainability from "../views/Sustainability";
import Highlights from "../views/Highlights";

// -------------------------------------------

const Routing = () => {
    return (
        <AnimatePresence exitBeforeEnter>
            <Routes>
                {/* route pages */}
                <Route exact path="/" element={<Home />} />
                {/* <Route exact path="/about" element={<About />} /> */}

                <Route exact path="/contact" element={<Contact />} />
                <Route exact path="/career" element={<Career />} />
                <Route exact path="/projects" element={<Projects />} />
                <Route exact path="/social-works" element={<SocialWorks />} />
                <Route exact path="/testimonial" element={<Testimonial />} />
                <Route exact path="/news" element={<News />} />

                {/* about */}
                <Route exact path="/journey" element={<Journey />} />
                <Route exact path="/team" element={<Team />} />
                <Route exact path="/awards" element={<Awards />} />
                <Route exact path="/news" element={<News />} />
                {/* about end*/}

                {/* clients */}
                <Route exact path="/clients" element={<Clients />} />
                {/* clients end*/}

                {/* VBridge + */}
                <Route exact path="/social-works" element={<SocialWorks />} />
                <Route exact path="/sustainability" element={<Sustainability />} />
                <Route exact path="/blogs" element={<Blogs />} />
                <Route exact path="/gallery" element={<Gallery />} />
                <Route exact path="/highlights" element={<Highlights />} />
                {/* VBridge + end*/}

                {/* Contact us*/}
                <Route exact path="/services" element={<Services />} />
                <Route exact path="/career" element={<Career />} />
                {/* Contact us end*/}

                {/* other pages */}
                <Route exact path="/terms" element={<Terms />} />
                {/* <Route exact path="/quote" element={<Quote />} /> */}
                <Route exact path="/privacy" element={<Privacy />} />
                <Route exact path="/faq" element={<Faq />} />
                {/* other pages end */}

                {/* route pages end*/}
            </Routes>
        </AnimatePresence>
    );
};

export default Routing;
