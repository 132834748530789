import React, { useState } from "react";
import { CLoader, FloatingSocial, ProjectView, Wrapper } from "../components";
import { Footer, Header } from "../layout";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { AnimatePresence } from "framer-motion";
import { ProjectCat, ProjectsCategories, ProjectsData, ProjectYears } from "../data/ProjectsData";
import { ProjectInteriorData } from "../data/ProjectInteriorData";
import { useIsSmall } from "../helpers/Hooks";

const NewProject = () => {
    const tabSettings = {
        defaultIndex: 0,
        forceRenderTabPanel: true,
    };

    const [showFullView, setShowFullView] = useState(false);
    const [currentFullViewProjectImg, setCurrentFullViewProjectImg] = useState([]);
    const [currentProjectData, setCurrentProjectData] = useState([]);

    const viewProject = (e) => {
        // current project id
        const currentProjectId = e.target.attributes.getNamedItem("data-id").value;
        // console.log(currentProjectId);

        // filter id
        ProjectInteriorData.filter((d) => d.id === currentProjectId).forEach((d) => {
            let images = [];
            // console.log(d.full_images);
            for (let i = 0; i < d.full_images.length; i++) {
                images.push(d.full_images[i]);
            }
            // console.log(images);
            setCurrentFullViewProjectImg(images);
        });
        ProjectInteriorData.filter((d) => d.id === currentProjectId).forEach((d) => {
            let info = {
                id: d.id,
                des: d.desc,
                name: d.name,
                year: d.time,
                client: d.client,
                value: d.value,
                area: d.area,
                design: d.design,
                status: d.status,
                category: d.category,
            };
            setCurrentProjectData([info]);
        });
        setShowFullView(true);
    };

    const [loading, setLoading] = useState(true);
    // small screen checker
    const isSmall = useIsSmall();
    return (
        <>
            <Wrapper id="new-projects" pageKey="vbridge__new__projects__page">
                {/* header */}
                <Header />
                {/* header end */}
                {loading && <CLoader />}
                {/* new project content */}
                <div className="projects">
                    <div className="container-fluid">
                        {/* <!-- projects tabs --> */}
                        <Tabs {...tabSettings}>
                            {/* <TabList className="project-tabs pb-3"> */}
                            <div className="project-tabs pb-3">
                                {ProjectsCategories.filter((cf) => cf.cat === "CHRONOLOGICAL").map((c) => {
                                    return (
                                        <h3 key={c.id} className="text-display m-0 p-0">
                                            PROJECTS
                                        </h3>
                                    );
                                })}
                            </div>
                            {/* </TabList> */}

                            {/* <!-- projects tabs end --> */}

                            {/* <!-- chronological content --> */}
                            <TabPanel>
                                <Tabs className="row">
                                    {/* <!-- time line --> */}
                                    <div className="col-lg-1 text-center">
                                        <h6 className="text-heading text-center text-uppercase">category</h6>
                                        <TabList className="time-line">
                                            {ProjectCat.map((pc) => {
                                                return <Tab key={pc.id}> {pc.cat}</Tab>;
                                            })}
                                            <div className="diamond"></div>
                                        </TabList>
                                    </div>
                                    <div className="col-lg-1"></div>
                                    {/* <!-- time line end --> */}

                                    <div className="col-lg-10">
                                        {/* all projects tab */}
                                        <TabPanel>
                                            <div className="row">
                                                {/* <!-- all projects projects --> */}
                                                <div className="col-lg-12">
                                                    <div className="all-projects">
                                                        <div className="row">
                                                            {ProjectInteriorData.map((d) => (
                                                                <div className="col-lg-4 item-padding">
                                                                    <div className="project" key={d.id}>
                                                                        <div className="pro-img">
                                                                            <img
                                                                                onLoad={() => setLoading(false)}
                                                                                src={d.projectThumb}
                                                                                alt=""
                                                                                key={d.id}
                                                                                data-id={d.id}
                                                                                onClick={(e) => viewProject(e)}
                                                                            />
                                                                        </div>
                                                                        <div className="info" data-id={d.id} onClick={(e) => viewProject(e)}>
                                                                            <h5
                                                                                className="text-heading"
                                                                                key={d.id}
                                                                                data-id={d.id}
                                                                                onClick={(e) => viewProject(e)}
                                                                            >
                                                                                {d.name}
                                                                            </h5>
                                                                            <p data-id={d.id} onClick={(e) => viewProject(e)}>{`${d.desc.substring(
                                                                                0,
                                                                                200
                                                                            )}`}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <!-- all projects projects end --> */}
                                            </div>
                                        </TabPanel>
                                        {/* all projects tab end*/}

                                        {/* COMMUNITY tab */}
                                        <TabPanel>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="all-projects">
                                                        <div className="row">
                                                            {/* <!-- ARCHITECTURE project --> */}
                                                            {ProjectInteriorData.filter((fd) => fd.category === "COMMUNITY").map((d) => (
                                                                <div className="col-lg-4 item-padding">
                                                                    <div className="project" key={d.id}>
                                                                        <div className="pro-img">
                                                                            <img
                                                                                onLoad={() => setLoading(false)}
                                                                                src={d.projectThumb}
                                                                                alt=""
                                                                                key={d.id}
                                                                                data-id={d.id}
                                                                                onClick={(e) => viewProject(e)}
                                                                            />
                                                                        </div>
                                                                        <div className="info" data-id={d.id} onClick={(e) => viewProject(e)}>
                                                                            <h5
                                                                                className="text-heading"
                                                                                key={d.id}
                                                                                data-id={d.id}
                                                                                onClick={(e) => viewProject(e)}
                                                                            >
                                                                                {d.name}
                                                                            </h5>
                                                                            <p data-id={d.id} onClick={(e) => viewProject(e)}>{`${d.desc.substring(
                                                                                0,
                                                                                200
                                                                            )}`}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            {/* <!-- ARCHITECTURE project end--> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        {/* COMMUNITY tab end */}

                                        {/* ARCHITECTURE tab */}
                                        <TabPanel>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="all-projects">
                                                        <div className="row">
                                                            {/* <!-- ARCHITECTURE project --> */}
                                                            {ProjectInteriorData.filter((fd) => fd.category === "ARCHITECTURE").map((d) => (
                                                                <div className="col-lg-4 item-padding">
                                                                    <div className="project" key={d.id}>
                                                                        <div className="pro-img">
                                                                            <img
                                                                                onLoad={() => setLoading(false)}
                                                                                src={d.projectThumb}
                                                                                alt=""
                                                                                key={d.id}
                                                                                data-id={d.id}
                                                                                onClick={(e) => viewProject(e)}
                                                                            />
                                                                        </div>
                                                                        <div className="info" data-id={d.id} onClick={(e) => viewProject(e)}>
                                                                            <h5
                                                                                className="text-heading"
                                                                                key={d.id}
                                                                                data-id={d.id}
                                                                                onClick={(e) => viewProject(e)}
                                                                            >
                                                                                {d.name}
                                                                            </h5>
                                                                            <p data-id={d.id} onClick={(e) => viewProject(e)}>{`${d.desc.substring(
                                                                                0,
                                                                                200
                                                                            )}`}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            {/* <!-- ARCHITECTURE project end--> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        {/* ARCHITECTURE tab end */}

                                        {/* INSTALLATION tab */}
                                        <TabPanel>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="all-projects">
                                                        <div className="row">
                                                            {/* <!-- ARCHITECTURE project --> */}
                                                            {ProjectInteriorData.filter((fd) => fd.category === "INSTALLATION").map((d) => (
                                                                <div className="col-lg-4 item-padding">
                                                                    <div className="project" key={d.id}>
                                                                        <div className="pro-img">
                                                                            <img
                                                                                onLoad={() => setLoading(false)}
                                                                                src={d.projectThumb}
                                                                                alt=""
                                                                                key={d.id}
                                                                                data-id={d.id}
                                                                                onClick={(e) => viewProject(e)}
                                                                            />
                                                                        </div>
                                                                        <div className="info" data-id={d.id} onClick={(e) => viewProject(e)}>
                                                                            <h5
                                                                                className="text-heading"
                                                                                key={d.id}
                                                                                data-id={d.id}
                                                                                onClick={(e) => viewProject(e)}
                                                                            >
                                                                                {d.name}
                                                                            </h5>
                                                                            <p data-id={d.id} onClick={(e) => viewProject(e)}>{`${d.desc.substring(
                                                                                0,
                                                                                200
                                                                            )}`}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            {/* <!-- ARCHITECTURE project end--> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        {/* INSTALLATION tab end */}

                                        {/* TOWNSHIP tab */}
                                        <TabPanel>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="all-projects">
                                                        <div className="row">
                                                            {/* <!-- ARCHITECTURE project --> */}
                                                            {ProjectInteriorData.filter((fd) => fd.category === "TOWNSHIP").map((d) => (
                                                                <div className="col-lg-4 item-padding">
                                                                    <div className="project" key={d.id}>
                                                                        <div className="pro-img">
                                                                            <img
                                                                                onLoad={() => setLoading(false)}
                                                                                src={d.projectThumb}
                                                                                alt=""
                                                                                key={d.id}
                                                                                data-id={d.id}
                                                                                onClick={(e) => viewProject(e)}
                                                                            />
                                                                        </div>
                                                                        <div className="info" data-id={d.id} onClick={(e) => viewProject(e)}>
                                                                            <h5
                                                                                className="text-heading"
                                                                                key={d.id}
                                                                                data-id={d.id}
                                                                                onClick={(e) => viewProject(e)}
                                                                            >
                                                                                {d.name}
                                                                            </h5>
                                                                            <p data-id={d.id} onClick={(e) => viewProject(e)}>{`${d.desc.substring(
                                                                                0,
                                                                                200
                                                                            )}`}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            {/* <!-- ARCHITECTURE project end--> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        {/* TOWNSHIP tab end */}

                                        {/* MODULAR tab */}
                                        <TabPanel>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="all-projects">
                                                        <div className="row">
                                                            {/* <!-- ARCHITECTURE project --> */}
                                                            {ProjectInteriorData.filter((fd) => fd.category === "MODULAR").map((d) => (
                                                                <div className="col-lg-4 item-padding">
                                                                    <div className="project" key={d.id}>
                                                                        <div className="pro-img">
                                                                            <img
                                                                                onLoad={() => setLoading(false)}
                                                                                src={d.projectThumb}
                                                                                alt=""
                                                                                key={d.id}
                                                                                data-id={d.id}
                                                                                onClick={(e) => viewProject(e)}
                                                                            />
                                                                        </div>
                                                                        <div className="info" data-id={d.id} onClick={(e) => viewProject(e)}>
                                                                            <h5
                                                                                className="text-heading"
                                                                                key={d.id}
                                                                                data-id={d.id}
                                                                                onClick={(e) => viewProject(e)}
                                                                            >
                                                                                {d.name}
                                                                            </h5>
                                                                            <p data-id={d.id} onClick={(e) => viewProject(e)}>{`${d.desc.substring(
                                                                                0,
                                                                                200
                                                                            )}`}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            {/* <!-- ARCHITECTURE project end--> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        {/* MODULAR tab end */}

                                        {/* INTERIOR tab */}
                                        <TabPanel>
                                            <div className="row">
                                                {/* <!-- INTERIOR projects --> */}
                                                <div className="col-lg-12">
                                                    <div className="all-projects">
                                                        <div className="row">
                                                            {ProjectInteriorData.filter((fd) => fd.category === "INTERIOR").map((d) => (
                                                                <div className="col-lg-4 item-padding">
                                                                    <div className="project" key={d.id}>
                                                                        <div className="pro-img">
                                                                            <img
                                                                                onLoad={() => setLoading(false)}
                                                                                src={d.projectThumb}
                                                                                alt=""
                                                                                key={d.id}
                                                                                data-id={d.id}
                                                                                onClick={(e) => viewProject(e)}
                                                                            />
                                                                        </div>
                                                                        <div className="info" data-id={d.id} onClick={(e) => viewProject(e)}>
                                                                            <h5
                                                                                className="text-heading"
                                                                                key={d.id}
                                                                                data-id={d.id}
                                                                                onClick={(e) => viewProject(e)}
                                                                            >
                                                                                {d.name}
                                                                            </h5>
                                                                            <p data-id={d.id} onClick={(e) => viewProject(e)}>{`${d.desc.substring(
                                                                                0,
                                                                                200
                                                                            )}`}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <!-- INTERIOR projects end --> */}
                                            </div>
                                        </TabPanel>
                                        {/* INTERIOR tab end*/}
                                    </div>
                                </Tabs>
                            </TabPanel>
                            {/* <!-- chronological content end--> */}
                        </Tabs>

                        {/* <!-- projects content end --> */}
                    </div>
                </div>
                {/* new project content end */}
                {/* floating social */}
                {!isSmall && <FloatingSocial />}
                {/* floating social end */}
                {/* footer */}
                <Footer />
                {/* footer end */}
            </Wrapper>
            <AnimatePresence>
                {showFullView && (
                    <ProjectView images={currentFullViewProjectImg} onClick={() => setShowFullView(false)} projectData={currentProjectData} />
                )}
            </AnimatePresence>
        </>
    );
};

export default NewProject;
