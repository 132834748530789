import { TbArrowsRandom } from "react-icons/tb";
import { RiBuilding2Fill } from "react-icons/ri";
import { VscSymbolStructure } from "react-icons/vsc";
import { BsPeopleFill } from "react-icons/bs";
import { AiOutlineTeam, AiFillFormatPainter, AiFillCalculator } from "react-icons/ai";

export const CareerData = [
    {
        id: "career1",
        name: "ARCHITECTURE TEAM",
        icon: <RiBuilding2Fill />,
    },
    {
        id: "career2",
        name: "STRUCTURAL TEAM",
        icon: <VscSymbolStructure />,
    },
    {
        id: "career3",
        name: "MEP TEAM",
        icon: <AiOutlineTeam />,
    },
    {
        id: "career4",
        name: "INTERIOR TEAM",
        icon: <AiFillFormatPainter />,
    },
    {
        id: "career5",
        name: "ACCOUNTS TEAM",
        icon: <AiFillCalculator />,
    },
    {
        id: "career6",
        name: "COMMUNICATION TEAM",
        icon: <BsPeopleFill />,
    },
    {
        id: "career7",
        name: "ECOSYSTEM DEPARTMENT",
        icon: <TbArrowsRandom />,
    },
];
