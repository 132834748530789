import {
    client__ndita,
    client__jis_group,
    client__jaguar,
    client__linde,
    client__nest_in,
    client__nkda,
    snu_logo,
    pasa_logo,
    iti_logo,
    ciiit_logo,
    bpcl_logo,
    havells_logo,
    philips_logo,
    asian_paints_logo,
    berger_paints_logo,
    ongc_logo,
    ishq_fm_logo,
    cognition_group_logo,
    tata_pravish_logo,
    haldia_petrochemical_logo,
    aastha_group_logo,
    ccon_digital_logo,
    saint_gobain_logo,
    igbc_logo,
    hunter_douglas_logo,
    cii_logo,
    kolkata_mnc_logo,
    amazon_logo,
    alucom_logo,
    everest_group_logo,
    red_fm_logo,
    itc_logo,
    ntpc_logo,
    e365_group_logo,
    techno_india_logo,
    tata_structura_logo,
    mandala_consultants_logo,
    bhutan_govt_logo,
    tata_steel_logo,
} from "../helpers/Images";

export const ClientsData = [
    {
        name: "ONGC",
        link: "https://www.ongcindia.com/",
        img_name: ongc_logo,
    },
    {
        name: "BPCL",
        link: "https://www.bharatpetroleum.in/",
        img_name: bpcl_logo,
    },
    {
        name: "NTPC",
        link: "https://www.ntpc.co.in/",
        img_name: ntpc_logo,
    },
    {
        name: "AMAZON",
        link: "https://www.amazon.in/",
        img_name: amazon_logo,
    },
    {
        name: "ITC",
        link: "https://www.itcportal.com/",
        img_name: itc_logo,
    },

    {
        name: "TATA STEEL",
        link: "https://www.tatasteel.com/",
        img_name: tata_steel_logo,
    },

    {
        name: "TATA STEEL",
        link: "https://www.tatasteel.com/",
        img_name: tata_steel_logo,
    },
    {
        name: "TATA PRAVESH",
        link: "https://www.tatapravesh.com/",
        img_name: tata_pravish_logo,
    },
    {
        name: "BERGER PAINTS",
        link: "https://www.bergerpaints.com/",
        img_name: berger_paints_logo,
    },
    {
        name: "ASIAN PAINTS",
        link: "https://www.asianpaints.com/",
        img_name: asian_paints_logo,
    },
    {
        name: "PHILLIPS",
        link: "https://www.philips.co.in/",
        img_name: philips_logo,
    },
    {
        id: "testi3",
        name: "Jaquar & Company Pvt. Ltd.",
        img_name: client__jaguar,
        link: "https://www.jaquar.com/en/",
    },
    {
        id: "testi5",
        name: "NDITA",
        img_name: client__ndita,
        link: "http://ndita.org/",
    },
    {
        name: "TECHNO INDIA ",
        link: "https://www.technoindiagroup.com/",
        img_name: techno_india_logo,
    },
    {
        id: "testi1",
        name: "JIS Group",
        img_name: client__jis_group,
        link: "https://www.jisgroup.org/",
    },
    {
        name: "BHUTAN GOVERNMENT",
        link: "https://www.gov.bt/",
        img_name: bhutan_govt_logo,
    },
    {
        name: "MANDALA CONSULTANTS",
        link: "https://www.facebook.com/mandalaconsultant/",
        img_name: mandala_consultants_logo,
    },

    {
        id: "testi6",
        name: "NKDA",
        img_name: client__nkda,
        link: "https://www.nkdamar.org/Pages/index.aspx",
    },

    {
        name: "E365 GROUP",
        link: "https://www.e365.in/",
        img_name: e365_group_logo,
    },

    {
        name: "RED FM",
        link: "https://www.redfmindia.in",
        img_name: red_fm_logo,
    },
    {
        name: "EVEREST GROUP",
        link: "https://www.everestgrp.com/",
        img_name: everest_group_logo,
    },
    {
        name: "ALUCOM",
        link: "https://alucom.co.in/",
        img_name: alucom_logo,
    },
    {
        name: "pasa",
        link: "https://alucom.co.in/",
        img_name: pasa_logo,
    },

    {
        name: "KOLKATA MUNICIPAL CORPORATION",
        link: "https://www.kmcgov.in/KMCPortal/jsp/KMCPortalHome1.jsp",
        img_name: kolkata_mnc_logo,
    },
    {
        name: "CII",
        link: "https://www.cii.in/",
        img_name: cii_logo,
    },
    {
        name: "HUNTER DOUGLAS",
        link: "https://www.hunterdouglas.com/",
        img_name: hunter_douglas_logo,
    },
    {
        name: "IGBC",
        link: "https://igbc.in/igbc/",
        img_name: igbc_logo,
    },
    {
        name: "SAINT GOBAIN",
        link: "https://www.saint-gobain.co.in/",
        img_name: saint_gobain_logo,
    },
    {
        name: "CCON DIGITAL",
        link: "http://ccondigital.com/",
        img_name: ccon_digital_logo,
    },
    {
        name: "AASTHA",
        link: "http://aasthagroup.co/index.html",
        img_name: aastha_group_logo,
    },
    {
        name: "HALDIA PETROCHEMICALS",
        link: "https://www.haldiapetrochemicals.com/",
        img_name: haldia_petrochemical_logo,
    },

    {
        id: "testi4",
        name: "Linde India Ltd.",

        img_name: client__linde,
        link: "https://www.linde.in/en/index.html",
    },
    {
        id: "testi2",
        name: "Nest-In",
        img_name: client__nest_in,
        link: "https://www.nestin.co.in/",
    },

    {
        name: "COGNITION GROUP",
        link: "https://www.cognitiongroup.co.in/",
        img_name: cognition_group_logo,
    },
    {
        name: "ISHQ FM",
        link: "http://www.fmishq.in/",
        img_name: ishq_fm_logo,
    },

    {
        name: "HAVELLS",
        link: "https://www.havells.com/",
        img_name: havells_logo,
    },

    {
        name: "CIIIT",
        link: "https://www.ciiitjammu.in/",
        img_name: ciiit_logo,
    },
    {
        name: "ITI",
        link: "https://iti.wb.gov.in/",
        img_name: iti_logo,
    },
    {
        name: "SNU",
        link: "https://snuniv.ac.in/",
        img_name: snu_logo,
    },
];
