import React, { useState } from "react";
import { Link, a, useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { Fade, FadeSlide } from "../../animation";
import { Cross } from "hamburger-react";
import { main__logo, navbar__img__1, navbar__img__2, navbar__img__3, navbar__img__4 } from "../../../helpers/Images";
import { useIsSmall } from "../../../helpers/Hooks";

import { IoIosArrowDown } from "react-icons/io";
// import { BsFacebook } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { ImFacebook2, ImLinkedin } from "react-icons/im";
import { IoLogoYoutube } from "react-icons/io";

import plusIcon from "../../../assets/images/plus.png";

const Navigation = () => {
    const navigate = useNavigate();

    const isSmall = useIsSmall();

    // navigation state
    const [active, setActive] = useState(false);
    // dropdown state
    const [dropActive, setDropActive] = useState(false);
    const [dropActive2, setDropActive2] = useState(false);
    const [dropActive3, setDropActive3] = useState(false);
    const [dropActive4, setDropActive4] = useState(false);
    // scrolled state
    // hello
    const [scrolled, setScrolled] = useState(false);

    const changeNavbarColor = () => {
        if (window.scrollY >= 80) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };
    window.addEventListener("scroll", changeNavbarColor);

    return (
        <div className="navigation" onWheel={() => setScrolled(true)}>
            <nav className="navOpacity">
                <div className="container-fluid">
                    <div className="brand">
                        <a href="/">
                            <img src={main__logo} alt="" />
                        </a>
                    </div>
                    <div className="d-flex">
                        <div className="nav-items-wrapper">
                            <div className="nav-item">
                                <a href="/" className={({ isActive }) => (isActive ? "active" : "inactive")}>
                                    <h5>HOME</h5>
                                </a>
                            </div>
                            <div className="nav-item">
                                <div className="nav-dropdown-wrapper" onMouseLeave={() => setDropActive(false)}>
                                    <button className="nav-dropdown-button" onMouseEnter={() => setDropActive(true)}>
                                        {/* <button className="nav-dropdown-button" onMouseEnter={()=>setDropActive(true)} onClick={() => setDropActive(!dropActive)}> */}
                                        About <IoIosArrowDown />
                                    </button>

                                    {dropActive && (
                                        <div className="nav-dropdown-menu">
                                            <Link to="/journey" className={({ isActive }) => (isActive ? "active" : "inactive")}>
                                                <h5>Journey</h5>
                                            </Link>
                                            <Link to="/team" className={({ isActive }) => (isActive ? "active" : "inactive")}>
                                                <h5>TEAM</h5>
                                            </Link>
                                            <Link to="/awards" className={({ isActive }) => (isActive ? "active" : "inactive")}>
                                                <h5>Awards and Recognition</h5>
                                            </Link>
                                            <Link to="/news" className={({ isActive }) => (isActive ? "active" : "inactive")}>
                                                <h5>news and Press</h5>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="nav-item">
                                <Link to="/projects" className={({ isActive }) => (isActive ? "active" : "inactive")}>
                                    <h5>PROJECTS</h5>
                                </Link>
                            </div>
                            <div className="nav-item">
                                <div className="nav-dropdown-wrapper" onMouseLeave={() => setDropActive4(false)}>
                                    <button className="nav-dropdown-button" onMouseEnter={() => setDropActive4(true)}>
                                        {/* <button className="nav-dropdown-button" onMouseEnter={()=>setDropActive(true)} onClick={() => setDropActive(!dropActive)}> */}
                                        Ecosystem <IoIosArrowDown />
                                    </button>

                                    {dropActive4 && (
                                        <div className="nav-dropdown-menu">
                                            <Link to="/clients" className={({ isActive }) => (isActive ? "active" : "inactive")}>
                                                <h5>Clients</h5>
                                            </Link>
                                            <Link to="/testimonial" className={({ isActive }) => (isActive ? "active" : "inactive")}>
                                                <h5>testimonials</h5>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="nav-item">
                                <div className="nav-dropdown-wrapper" onMouseLeave={() => setDropActive3(false)}>
                                    <button className="nav-dropdown-button" onMouseEnter={() => setDropActive3(true)}>
                                        VBridge{" "}
                                        <span className="icon">
                                            <img src={plusIcon} alt="" />{" "}
                                        </span>{" "}
                                        {/* <IoIosArrowDown /> */}
                                    </button>

                                    {dropActive3 && (
                                        <div className="nav-dropdown-menu">
                                            <Link to="/social-works" className={({ isActive }) => (isActive ? "active" : "inactive")}>
                                                <h5>CSR</h5>
                                            </Link>
                                            <Link to="/sustainability" className={({ isActive }) => (isActive ? "active" : "inactive")}>
                                                <h5>Sustainability</h5>
                                            </Link>
                                            {/* <a href="/blogs" className={({ isActive }) => (isActive ? "active" : "inactive")}>
                                                <h5>Blogs</h5>
                                            </a> */}

                                            <Link to="/gallery" className={({ isActive }) => (isActive ? "active" : "inactive")}>
                                                <h5>Gallery</h5>
                                            </Link>
                                            <Link to="/highlights" className={({ isActive }) => (isActive ? "active" : "inactive")}>
                                                <h5>Key highlights</h5>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="nav-item">
                                <div className="nav-dropdown-wrapper" onMouseLeave={() => setDropActive2(false)}>
                                    <button className="nav-dropdown-button" onMouseEnter={() => setDropActive2(true)}>
                                        Contact Us <IoIosArrowDown />
                                    </button>

                                    {dropActive2 && (
                                        <div className="nav-dropdown-menu">
                                            <Link to="/services" className={({ isActive }) => (isActive ? "active" : "inactive")}>
                                                <h5>Services</h5>
                                            </Link>
                                            <Link to="/career" className={({ isActive }) => (isActive ? "active" : "inactive")}>
                                                <h5>Career</h5>
                                            </Link>
                                            <Link to="/contact" className={({ isActive }) => (isActive ? "active" : "inactive")}>
                                                <h5>offices</h5>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* toggle button for the small screen */}
                        {isSmall && (
                            <div className="toggle-icon" onClick={() => setActive(!active)}>
                                <Cross size={26} />
                            </div>
                        )}
                        {/* toggle button for the small screen */}
                    </div>
                </div>
            </nav>
            <AnimatePresence>
                {active && (
                    <Fade uqkey="vbridge__main__nav">
                        <div className="custom-nav">
                            <div className="container">
                                {/* nav mobile */}
                                <div className="nav-mobile">
                                    {/*  mobile nav Items */}
                                    <div className="items">
                                        <FadeSlide uqkey="nav__m_1" delayTime={0.3}>
                                            <a href="/">
                                                <div className="item item-black text-white">Home</div>
                                            </a>
                                        </FadeSlide>
                                        <FadeSlide uqkey="nav__m_3" delayTime={0.5}>
                                            <Link to="/journey">
                                                <div className="item item-black text-white">Journey</div>
                                            </Link>
                                        </FadeSlide>
                                        <FadeSlide uqkey="nav__m_1" delayTime={0.1}>
                                            <Link to="/team">
                                                <div className="item item-blue">TEAM</div>
                                            </Link>
                                        </FadeSlide>
                                    </div>
                                    <div className="items">
                                        <FadeSlide uqkey="nav__m_3" delayTime={0.5}>
                                            <Link to="/news">
                                                <div className="item item-black text-white">
                                                    NEWS <br /> & <br /> press
                                                </div>
                                            </Link>
                                        </FadeSlide>
                                        <FadeSlide uqkey="nav__m_4" delayTime={0.6}>
                                            <Link to="/awards">
                                                <div className="item item-blue">
                                                    Awards <br />&<br /> Recognition
                                                </div>
                                            </Link>
                                        </FadeSlide>
                                        <FadeSlide uqkey="nav__m_6" delayTime={1.3}>
                                            <Link to="/projects">
                                                <div className="item item-black text-white"> PROJECTS</div>
                                            </Link>
                                        </FadeSlide>
                                    </div>
                                    <div className="items">
                                        <FadeSlide uqkey="nav__m_8" delayTime={0.3}>
                                            <Link to="/clients">
                                                <div className="item item-black text-white">Clients</div>
                                            </Link>
                                        </FadeSlide>
                                        <FadeSlide uqkey="nav__m_8" delayTime={0.3}>
                                            <Link to="/testimonial">
                                                <div className="item item-blue">testimonials</div>
                                            </Link>
                                        </FadeSlide>
                                        <FadeSlide uqkey="nav__m_8" delayTime={0.3}>
                                            <Link to="/social-works">
                                                <div className="item item-black text-white">CSR</div>
                                            </Link>
                                        </FadeSlide>
                                    </div>
                                    <div className="items">
                                        <FadeSlide uqkey="nav__m_6" delayTime={1.3}>
                                            <Link to="/sustainability">
                                                <div className="item item-blue">Sustainability</div>
                                            </Link>
                                        </FadeSlide>
                                        <FadeSlide uqkey="nav__m_8" delayTime={0.3}>
                                            <Link to="/gallery">
                                                <div className="item item-black text-white">Gallery</div>
                                            </Link>
                                        </FadeSlide>
                                        <FadeSlide uqkey="nav__m_8" delayTime={0.3}>
                                            <Link to="/highlights">
                                                <div className="item item-blue">Key highlights</div>
                                            </Link>
                                        </FadeSlide>
                                    </div>
                                    <div className="items">
                                        <FadeSlide uqkey="nav__m_8" delayTime={0.3}>
                                            <Link to="/services">
                                                <div className="item item-black text-white">Services</div>
                                            </Link>
                                        </FadeSlide>
                                        <FadeSlide uqkey="nav__m_8" delayTime={0.3}>
                                            <Link to="/career">
                                                <div className="item item-blue">Career</div>
                                            </Link>
                                        </FadeSlide>
                                        <FadeSlide uqkey="nav__m_8" delayTime={0.3}>
                                            <Link to="/contact">
                                                <div className="item item-black text-white">offices</div>
                                            </Link>
                                        </FadeSlide>
                                    </div>
                                    {/*  mobile nav Items end */}

                                    {/*  mobile social Items */}
                                    <div className="items items-social">
                                        <FadeSlide uqkey="nav__m_10" delayTime={0.3}>
                                            <div
                                                className="item item-blue"
                                                onClick={() => window.open("https://www.facebook.com/abridge2k17/", "_blank")}
                                            >
                                                <ImFacebook2 size={25} />
                                            </div>
                                        </FadeSlide>
                                        <FadeSlide uqkey="nav__m_10" delayTime={0.3}>
                                            <div
                                                className="item item-black text-white"
                                                onClick={() => window.open("https://in.linkedin.com/company/vbridge2k17", "_blank")}
                                            >
                                                <ImLinkedin size={25} />
                                            </div>
                                        </FadeSlide>
                                        <FadeSlide uqkey="nav__m_12" delayTime={0.9}>
                                            <div
                                                className="item item-blue"
                                                onClick={() => window.open("https://www.instagram.com/vbridgemegacorp/", "_blank")}
                                            >
                                                <AiFillInstagram size={30} />
                                            </div>
                                        </FadeSlide>
                                        <FadeSlide uqkey="nav__m_12" delayTime={0.9}>
                                            <div
                                                className="item item-black text-white"
                                                onClick={() =>
                                                    window.open("https://www.youtube.com/channel/UCWkj9y0Akz_bHiCWMebzyWA/videos", "_blank")
                                                }
                                            >
                                                <IoLogoYoutube size={30} />
                                            </div>
                                        </FadeSlide>
                                    </div>
                                    {/*  mobile social Items end*/}
                                </div>
                                {/* nav mobile end */}
                            </div>
                        </div>
                    </Fade>
                )}
            </AnimatePresence>
        </div>
    );
};

export default Navigation;
