import React from "react";
import { CareerForm, FloatingSocial, Service, Wrapper } from "../components";
import { CareerData } from "../data/CareerData";
import { useIsSmall } from "../helpers/Hooks";
import { Footer, Header } from "../layout";

const Carrer = () => {
    // small screen checker
    const isSmall = useIsSmall();
    return (
        <Wrapper id="career" pageKey="vbridge__career__page">
            {/* header */}
            <Header />
            {/* header end */}

            {/* career works */}
            {/* services */}
            <div className="container">
                <h1 className="mt-4 text-center text-display text-uppercase">work with us</h1>
                {/* <div className="row"> */}
                <div className="flex-box-horizontal">
                    {CareerData.map(
                        (d, i) =>
                            i <= 2 && (
                                <div className="col-lg-2 item-padding" key={d.id}>
                                    <Service name={d.name} icon={d.icon} />
                                </div>
                            )
                    )}
                </div>
                <div className="flex-box-horizontal">
                    {CareerData.map(
                        (d, i) =>
                            i > 2 &&
                            i < 7 && (
                                <div className="col-lg-2 item-padding" key={d.id}>
                                    <Service name={d.name} icon={d.icon} />
                                </div>
                            )
                    )}
                </div>
                <div className="col-lg-12 item-padding mt-5">
                    <CareerForm />
                </div>
                {/* </div> */}
            </div>
            {/* services end */}
            {/* career works end */}

            {/* career form */}
            {/* <div className="container mb-5">
                <CareerForm />
            </div> */}
            {/* career form end */}
            {/* floating social */}
            {!isSmall && <FloatingSocial />}
            {/* floating social end */}

            {/* footer */}
            <Footer />
            {/* footer end */}
        </Wrapper>
    );
};

export default Carrer;
