import React, { useState } from "react";
import { CLoader, FloatingSocial, Wrapper } from "../components";
import { Footer, Header } from "../layout";
import { HomeIntroSlider } from "../data/HomeData";
import { useIsSmall } from "../helpers/Hooks";

import VB_Portfolio from "../assets/pdf/VBRIDGE_MEGACORP_2022.pdf";
import { Button } from "bootstrap";

const Highlights = () => {
    const [loading, setLoading] = useState(true);
    // small screen checker
    const isSmall = useIsSmall();
    return (
        <Wrapper id="highlights" pageKey="vbridge__new__projects__page">
            {/* header */}
            <Header />
            {/* header end */}
            {loading && <CLoader />}

            <div className="container">
                <h1 className="text-display text-center pb-5 text-uppercase"> key highlights </h1>
                {HomeIntroSlider.map((d) => (
                    <div className="highlight-img" key={d.id}>
                        <img src={d.img} alt="" onLoad={() => setLoading(false)} />
                    </div>
                ))}
            </div>
            <div className="text-center my-5">
                <a href={VB_Portfolio} target="_blank" className="btn-primary p-3 bg-dark text-uppercase text-subheading">
                    Download our portfolio
                </a>
            </div>
            {/* floating social */}
            {!isSmall && <FloatingSocial />}
            {/* floating social end */}
            {/* footer */}
            <Footer />
            {/* footer end */}
        </Wrapper>
    );
};

export default Highlights;
