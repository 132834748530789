import React, { useEffect, useState } from "react";
import { CLoader, FloatingSocial, Wrapper } from "../components";
import { Footer, Header } from "../layout";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

// news images
import { FadeSlide } from "../components/animation";
import { NACat, NewsData, NewsYear } from "../data/NewsData";
import { Link, useNavigate } from "react-router-dom";
import { useIsSmall } from "../helpers/Hooks";

const Blogs = () => {
    const [loading, setLoading] = useState(true);
    // small screen checker
    const isSmall = useIsSmall();
    return (
        <Wrapper id="news" pageKey="vbridge__news__page">
            {/* header */}
            <Header />
            {/* header end */}

            {loading && <CLoader />}
            <div id="new-projects" style={{ paddingTop: "0px" }}>
                <div className="container">
                    <h2 className="text-display text-uppercase text-center py-5"> latest Blogs</h2>
                    <div className="projects">
                        <div className="news news-page">
                            {/* news */}
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row">
                                        {NewsData.map((news, i) => {
                                            return (
                                                <div className="col-lg-3 item-padding" key={i}>
                                                    <FadeSlide uqkey="news__quickUpdate__3" delayTime={0.7}>
                                                        <div className="news-item" onClick={() => window.open(news.link, "_blank")}>
                                                            <div className="news-img">
                                                                <img onLoad={() => setLoading(false)} src={news.thumbnail} alt="" />
                                                            </div>
                                                            <div className="news-details-text">
                                                                <div className="news-content py-2">
                                                                    <h5 className="text-paragraph">{`${news.title.substring(0, 40)}...`}</h5>
                                                                </div>
                                                                {/* <div className="news-author pt-0 pb-3">
                                                                    <div className="avater">
                                                                        <img onLoad={() => setLoading(false)} src={news.source} alt="" />
                                                                    </div>
                                                                    <div className="author">
                                                                        <h5 className="text-subheading p-0">{news.SourceName}</h5>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </FadeSlide>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            {/* news end */}
                        </div>
                    </div>
                </div>
            </div>
            {/* floating social */}
            <FloatingSocial />
            {/* floating social end */}
            {/* footer */}
            <Footer />
            {/* footer end */}
        </Wrapper>
    );
};

export default Blogs;
