import React from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { FloatingSocial, Wrapper } from "../components";
import { JourneyData } from "../data/JourneyData";
import { useIsSmall } from "../helpers/Hooks";
import { Footer, Header } from "../layout";

const Journey = () => {
    // small screen checker
    const isSmall = useIsSmall();
    return (
        <Wrapper id="awards" pageKey="vbridge__home__page">
            {/* header */}
            <Header />
            {/* header end */}

            <div className="container">
                {/* <div className="about-company">
                    <h2 className="text-uppercase text-display">About the company description -</h2>
                    <p className="text-paragraph pt-3">
                        At VBRIDGE, our purpose is to design a better world to live in. We aim to provide every single design service under one roof!
                    </p>
                    <p className="text-paragraph">
                        We have been delivering quality projects in areas of Architecture, Interior, Township, Modular and Installation since late
                        2019 and have completed 100+ projects since then.
                    </p>
                    <p className="text-paragraph">
                        The VBRIDGE brand has two separate entities, VBRIDGE partners and VBRIDGE Engineering and Project Private Limited (VEPPL)
                    </p>
                    <p className="text-paragraph">
                        VBRIDGE Partners officially started studios in late 2019 and started working on Architectural projects.
                    </p>
                    <p className="text-paragraph">
                        VBRIDGE partners incubated VBRIDGE Engineering and Project Private Limited (VBEPPL) in 2020 and was officially registered in
                        September 2021.{" "}
                    </p>
                    <p className="text-paragraph">Today we have offices in Kolkata, Mumbai, and Kuala Lumpur and are a team of 20+ strong members.</p>
                </div> */}

                <h2 className="text-center text-display pt-5">Our Journey</h2>
                <p className="text-center pt-5 text-subheading" style={{ fontSize: "18px" }}>
                    At VBRIDGE, our purpose is to design a better world to live in. We aim to provide every single design service under one roof! We
                    have been delivering quality projects in areas of Architecture, Interior, Township, Modular and Installation since late 2019 and
                    have completed 100+ projects since then. The VBRIDGE brand has two separate entities, VBRIDGE partners and VBRIDGE Engineering and
                    Project Private Limited (VEPPL) VBRIDGE Partners officially started studios in late 2019 and started working on Architectural
                    projects. VBRIDGE partners incubated VBRIDGE Engineering and Project Private Limited (VEPPL) in 2020 and was officially registered
                    in September 2021. Today we have offices in Kolkata, Mumbai, and Kuala Lumpur and are a team of 20+ strong members.
                </p>
                <div className="timeline-wrapper">
                    <VerticalTimeline>
                        {JourneyData.map((jd) => (
                            <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                contentStyle={{ background: `#${jd.color}`, color: "#fff" }}
                                contentArrowStyle={{ borderRight: `7px solid  #${jd.color}` }}
                                date=""
                                iconStyle={{
                                    background: `#${jd.color}`,
                                    color: "#fff",
                                    borderRadius: "0",
                                    transform: "rotate(-45deg) skew(20deg, 20deg)",
                                }}
                                key={jd.id}
                            >
                                <h3 className="text-heading">{jd.name}</h3>
                                <h4 className="text-subheading">{jd.date}</h4>
                                <p className="text-paragraph">{jd.desc}</p>
                            </VerticalTimelineElement>
                        ))}
                    </VerticalTimeline>
                </div>
            </div>

            {/* floating social */}
            {!isSmall && <FloatingSocial />}
            {/* floating social end */}

            {/* footer */}
            <Footer />
            {/* footer end */}
        </Wrapper>
    );
};

export default Journey;
