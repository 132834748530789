import React, { useState, useRef } from "react";
import { useFormspark } from "@formspark/use-formspark";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";

import CLoader from "../base/loader/CLoader";

const ContactForm = () => {
    // ref input fields
    const fullNameRef = useRef(null);
    const messageRef = useRef(null);
    const emailRef = useRef(null);
    const phoneRef = useRef(null);

    // uploading state
    const [uploading, setUploading] = useState();

    // success state
    const [successMessage, setSuccessMessage] = useState("Send");

    // form values states
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");

    // form spark
    const FORMSPARK_FORM_ID = "QPVOHn6k";

    const [submit, submitting] = useFormspark({
        formId: FORMSPARK_FORM_ID,
    });

    // contact form handler
    const contactFormHandler = async (e) => {
        e.preventDefault();
        try {
            setUploading(true);
            const send = await submit({ fullName, email, phone, message });
            if (send) {
                setSuccessMessage("Successfully send");
            }
            e.target.reset();
            setUploading(false);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            {" "}
            {uploading && <CLoader />}
            <form onSubmit={contactFormHandler}>
                <h2>reach out to us</h2>
                <div className="input-box">
                    <input type="text" ref={fullNameRef} placeholder="Full Name" name="fullName" onChange={(e) => setFullName(e.target.value)} />
                    <span className="border"></span>
                </div>
                <div className="input-box">
                    <input type="email" ref={emailRef} placeholder="Email" name="email" onChange={(e) => setEmail(e.target.value)} />
                    <span className="border"></span>
                </div>
                <div className="input-box">
                    <input type="text" ref={phoneRef} placeholder="Phone No" name="phone" onChange={(e) => setPhone(e.target.value)} />
                    <span className="border"></span>
                </div>
                <div className="input-box">
                    <textarea ref={messageRef} placeholder="Message..." name="message" onChange={(e) => setMessage(e.target.value)}></textarea>
                    <span className="border"></span>
                </div>
                <div className="float-right">
                    {successMessage === "Successfully send" ? (
                        <button className="btn-custom mt-3" disabled="true" style={{ backgroundColor: "green", color: "white", cursor: "no-drop" }}>
                            Successful <IoCheckmarkDoneCircleSharp />
                        </button>
                    ) : (
                        <button className="btn-custom mt-3" type="submit" disabled={submitting}>
                            Send
                        </button>
                    )}
                </div>
            </form>
        </>
    );
};

export default ContactForm;
