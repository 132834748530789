import React, { useRef, useState } from "react";
import { useFormspark } from "@formspark/use-formspark";
import { BiUpload } from "react-icons/bi";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import { Widget, uploadcare } from "@uploadcare/react-widget";

import CLoader from "../../base/loader/CLoader";

const CareerForm = () => {
    // ref input fields
    const fullNameRef = useRef(null);
    const emailRef = useRef(null);
    const phoneNoRef = useRef(null);
    const reasonRef = useRef(null);
    const messageRef = useRef(null);
    // getting the upload file view on click
    const uploadRef = useRef(null);

    // form values states
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [reason, setReason] = useState("");
    const [message, setMessage] = useState("");
    const [cv, setCv] = useState();
    // success state
    const [successMessage, setSuccessMessage] = useState("Send");
    // uploading state
    const [uploading, setUploading] = useState();

    // upload area clicked function
    const openUpload = () => {
        uploadRef.current.click();
    };

    // handle the upload file

    const uploadHandler = (e) => {
        setCv(e.target.files[0]);
    };

    const fileHandler = (info) => {
        setCv(info.originalUrl);
    };

    // form spark
    const FORMSPARK_FORM_ID = "jWj9P7BT";
    const UPLOADCARE_ID = "3cdb6597569d49a6bad6";

    const [submit, submitting] = useFormspark({
        formId: FORMSPARK_FORM_ID,
    });

    // career form handler
    const careerFormHandler = async (e) => {
        e.preventDefault();
        try {
            setUploading(true);
            const send = await submit({ fullName, email, phone, reason, message, cv });
            if (send) {
                setSuccessMessage("Successfully send");
            }
            e.target.reset();
            setUploading(false);
            uploadRef.current.value(null);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            {uploading && <CLoader />}
            <div className="application-form">
                <h2 className="text-display">SEND YOUR application</h2>
                <form action="" onSubmit={careerFormHandler}>
                    <div className="row">
                        <div className="col-lg-6 px-4">
                            {/* full name */}
                            <div className="input-box">
                                <input
                                    ref={fullNameRef}
                                    type="text"
                                    placeholder="Full Name"
                                    name="fullName"
                                    onChange={(e) => setFullName(e.target.value)}
                                />
                                <span class="border"></span>
                            </div>
                            {/* full name end */}

                            {/* email */}
                            <div className="input-box">
                                <input ref={emailRef} type="email" placeholder="Email" name="email" onChange={(e) => setEmail(e.target.value)} />
                                <span class="border"></span>
                            </div>
                            {/* email end */}

                            {/* phone no */}
                            <div className="input-box">
                                <input
                                    ref={phoneNoRef}
                                    type="phone"
                                    placeholder="Phone No."
                                    name="phone"
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                                <span class="border"></span>
                            </div>
                            {/* phone no end */}
                        </div>
                        <div className="col-lg-6 px-4">
                            {/* select reason */}
                            <div className="input-box">
                                <select name="reason" id="cars" ref={reasonRef} onChange={(e) => setReason(e.target.value)}>
                                    <option value="-">Select Post You Want to Apply</option>
                                    <option value="ARCHITECTURE-TEAM">ARCHITECTURE TEAM</option>
                                    <option value="STRUCTURAL-TEAM">STRUCTURAL TEAM</option>
                                    <option value="MEP-TEAM">MEP TEAM</option>
                                    <option value="INTERIOR-TEAM">INTERIOR TEAM</option>
                                    <option value="ACCOUNTS-TEAM">ACCOUNTS TEAM</option>
                                    <option value="COMMUNICATION-TEAM">COMMUNICATION-TEAM</option>
                                    <option value="ECOSYSTEM-DEPARTMENT">ECOSYSTEM DEPARTMENT</option>
                                </select>
                                <span class="border"></span>
                            </div>
                            {/* select reason */}

                            {/* cv upload */}
                            <div className="cv" onClick={openUpload}>
                                <label for="">Upload Your Portfolio</label>
                                {/* <input
                                    type="file"
                                    hidden
                                    ref={uploadRef}
                                    onChange={uploadHandler}
                                    name="cv"
                                    id="photo"
                                    data-public-key="3cdb6597569d49a6bad6"
                                /> */}
                                <div style={{ marginTop: "-20px" }}>
                                    <Widget
                                        previewStep
                                        ref={uploadRef}
                                        clearable={true}
                                        publicKey={UPLOADCARE_ID}
                                        tabs="file"
                                        onChange={(info) => fileHandler(info)}
                                    />
                                    {/* <BiUpload size={20} /> */}
                                </div>
                            </div>
                            {/* cv upload end */}

                            {/* message */}
                            <textarea
                                ref={messageRef}
                                placeholder="Brief Message For Recruiters..."
                                name="message"
                                onChange={(e) => setMessage(e.target.value)}
                            ></textarea>
                            {/* message end */}

                            {/* submit button */}
                            {successMessage === "Successfully send" ? (
                                <button
                                    className="btn-custom"
                                    disabled="true"
                                    style={{ backgroundColor: "green", color: "white", cursor: "no-drop" }}
                                >
                                    Successful <IoCheckmarkDoneCircleSharp />
                                </button>
                            ) : (
                                <button className="btn-custom" type="submit" disabled={submitting}>
                                    Apply
                                </button>
                            )}
                            {/* submit button end */}
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default CareerForm;
