import React from "react";

const Sustainabilities = ({ sustainPercentage, sustainInfo, sustainIcon, color }) => {
    return (
        <div className="sustain">
            <div className="row">
                <div className="col-lg-1 item-padding">
                    <div className="icon" style={{ backgroundColor: color }}>
                        {sustainIcon}
                    </div>
                </div>
                <div className="col-lg-11 item-padding">
                    <div className="sustain-info" style={{ borderBottom: `4px solid ${color} ` }}>
                        <h5 className="text-heading">{sustainPercentage}</h5>
                        <p className="text-paragraph">{sustainInfo}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sustainabilities;
