import React from "react";

// home page components
import { FloatingScroll, FloatingSocial, HomeIntro, MetricsCounter, Testimonials, Wrapper } from "../components";
import HomeAwards from "../components/home/home-awards/HomeAwards";
import { useIsSmall } from "../helpers/Hooks";

// layout components
import { Footer, Header } from "../layout";

const Home = () => {
    // small screen checker
    const isSmall = useIsSmall();
    return (
        <Wrapper id="home" pageKey="vbridge__home__page">
            {/* header */}
            <Header />
            {/* header end */}

            {/* intro */}
            <HomeIntro />
            {/* intro end */}

            {/* Metrics Counter  */}
            <MetricsCounter />
            {/* Metrics Counter end */}

            {/* awards timeline */}
            <HomeAwards />
            {/* awards timeline end */}
            {/* testimonials */}
            <div className="container">
                <h2 className="pb-3 text-display text-center">Testimonials</h2>
                <p className="text-subheading text-center">
                    VBRIDGE Team always believes in receiving feedback from their clients, well wishers & user group.That helps VBRIDGE to learn and
                    keep growing. <br />
                    Here is what few of the stalwarts and eminent personalities had to say about our Team.
                </p>

                <div className="row">
                    {/* testimonials */}
                    <div className="col-lg-12">
                        <Testimonials />
                    </div>
                </div>
            </div>
            {/* testimonials end */}

            {/* floating scroll */}
            {!isSmall && <FloatingScroll />}

            {/* floating scroll end */}

            {/* floating social */}
            {!isSmall && <FloatingSocial />}
            {/* floating social end */}

            {/* footer */}
            <Footer />
            {/* footer end */}
        </Wrapper>
    );
};

export default Home;
