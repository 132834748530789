import React from "react";
import CountUp from "react-countup";

const MetricsCounter = () => {
    return (
        <div className="metrics-counter-wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-6">
                        <div className="counter">
                            <h3 className="head">
                                <CountUp end={2} delay={3} />+
                            </h3>
                            <p className="head-info">YEARS</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6">
                        <div className="counter">
                            <h3 className="head">
                                {" "}
                                <CountUp end={100} delay={3} />+
                            </h3>
                            <p className="head-info">PROJECTS</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6">
                        <div className="counter">
                            <h3 className="head">
                                {" "}
                                <CountUp end={25} delay={3} />+
                            </h3>
                            <p className="head-info text-center">AWARDS & RECOGNITION</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6">
                        <div className="counter">
                            <h3 className="head">
                                {" "}
                                <CountUp end={1.5} delay={3} decimals={1} /> MILLION+
                            </h3>
                            <p className="head-info">SQ. FT.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 pt-4">
                        <div className="counter">
                            <h3 className="head">
                                {" "}
                                <CountUp end={5} delay={3} /> MILLION+
                            </h3>
                            <p className="head-info text-center"> LIVES IMPACTED</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-6 pt-4">
                        <div className="counter">
                            <h3 className="head">
                                {" "}
                                <CountUp end={4.1} delay={3} decimals={1} />
                                /5
                            </h3>
                            <p className="head-info text-center">EMPLOYEE HAPPINESS INDEX</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 pt-4">
                        <div className="counter">
                            <h3 className="head">
                                {" "}
                                <CountUp end={22} delay={3} />% (Q-o-Q)
                            </h3>
                            <p className="head-info text-center">NET ZERO INITIATIVE GROWTH</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 pt-4">
                        <div className="counter">
                            <h3 className="head">
                                {" "}
                                <CountUp end={0.24} delay={3} decimals={2} /> MILLION
                            </h3>
                            <p className="head-info">GREEN SQUAREFOOT</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MetricsCounter;
