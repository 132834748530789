import React from "react";
import { Link } from "react-router-dom";
import { Award, FloatingSocial, Wrapper } from "../components";
import { AwardsData } from "../data/AwardsData";
import { useIsSmall } from "../helpers/Hooks";
import { Footer, Header } from "../layout";

const Awards = () => {
    // small screen checker
    const isSmall = useIsSmall();
    return (
        <Wrapper id="awards" pageKey="vbridge__news__page">
            {/* header */}
            <Header />
            {/* header end */}

            {/* awards timeline */}
            <div className="container">
                <h2 className="pb-3 text-display text-center">Awards and Recognition</h2>
                <div className="awards-wrapper pb-0">
                    <div className="row">
                        {AwardsData.map((ad) => (
                            <div className="col-lg-6" key={ad.id}>
                                <Award year={ad.year} title={ad.title} place={ad.place} info={ad.info} org={ad.org} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="text-center mb-5">
                    <Link to="/news" className="btn-primary p-3 bg-dark text-uppercase text-subheading">
                        Read Latest News
                    </Link>
                </div>
            </div>
            {/* awards timeline end */}

            {/* floating social */}
            {!isSmall && <FloatingSocial />}
            {/* floating social end */}

            {/* footer */}
            <Footer />
            {/* footer end */}
        </Wrapper>
    );
};

export default Awards;
